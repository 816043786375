import { createRoot } from "react-dom/client";
import React, { StrictMode } from "react";

import { LicenseInfo } from "@mui/x-license-pro";

import { datadogRum } from "@datadog/browser-rum";
import { tracker } from "./tracker";
import { config } from "./config";

/**
 * Bootstrap the React application and executes various
 * essential set-up tasks.
 */
export function bootstrap(elementId: string, children: React.ReactNode) {
  // set up events tracker
  tracker.initTracker();
  // MUI-X Premium License
  // Note that it's ok that this license is publicly visible in the browser.
  // See https://mui.com/x/introduction/licensing/#how-to-install-the-key
  LicenseInfo.setLicenseKey(
    "0bfc2858867cb73d71caeca6399fc0f9Tz03NDU0NyxFPTE3MjYyMjE0ODcwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y",
  );

  if (config.ddEnabled) {
    //
    // Datadog Real User Monitoring
    //
    datadogRum.init({
      applicationId: "1d3a42e2-faae-4190-baa7-7a448ad6439b",
      clientToken: "pub8cd504e59f51859001ec494538f97fb6",
      site: "datadoghq.eu",
      service: "web-nextgen",
      env: config.ddEnvironment,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: APP_VERSION,
      traceSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask",
      allowedTracingUrls: config.ddTracingOrigins,
    });

    datadogRum.startSessionReplayRecording();
  }

  const container = document.getElementById(elementId);

  if (container !== null) {
    const root = createRoot(container);
    root.render(<StrictMode>{children}</StrictMode>);
  }
}
